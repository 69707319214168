import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";
import AosEvent from "../../components/event/aos";

const About = () => (
  <Layout pageTitle="Made You Love Tennis Foundation | Atlanta Open Showdown">
    <HeaderTwo />
    <div className="about-page">
      <StickyHeader extraClassName="stricky-header-two" />
      <PageHeader title="Atlanta Open Showdown" crumbTitle="Atlanta Open Showdown" />
      <AosEvent top={true} showbutton={true}/>
    </div>
    <Footer />
  </Layout>
);

export default About;
