// import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import heart from "../../assets/images/shapes/Green-ball.png";
import { Link } from "@gatsbyjs/reach-router";

const AakdEvent = ({ showbutton, top }) => {
  return (
    <section className={`${top && "pt-120"} pb-120`}>
      <Container>
        <Row className="pb-40">
          <Col lg={10}>
            <div className="donate-options__content">
              <div className="block-title">
                <p>
                  <img src={heart} width="15" alt="" />
                  July 21, 2024
                </p>
                <h3>Atlanta Open Showdown</h3>
              </div>
              <p>
                Experience tennis like never before! An Exclusive Match
                Experience with Tennis Legend Eric Jackson evening with Eric
                Jackson, Founder of MYLT, at the Final ATL Showdown Venus
                Williams vs Sloane Stephens on Sunday, July 21, 2024.
              </p>
              <p>Exclusive Match Experience:</p>
              <ul>
                <li>
                  Premier lower-level seating to the Final ATL Showdown Tennis
                  Match at 7 pm
                </li>
                <li>
                  Exclusive Social Hour @ Rose Bistro & Champagne Bar- featuring
                  curated cocktails & bites
                </li>
                <li>Access to Private Tennis Training with Eric Jackson</li>
                <li>
                  Raffle Entry for the Eric Jackson Tennis Experience in
                  Anguilla ($15K value)
                </li>
                <li>Swag Bag</li>
              </ul>
              <p>A $1500 value - Purchase your experience for $450</p>
            </div>
          </Col>
        </Row>
        {/* {showbutton && (
          <a href="https://google.com" target="_blank">
            <button className="thm-btn dynamic-radius">Buy Tickets</button>
          </a>
        )} */}
        <Link className="thm-btn dynamic-radius" to="/contact">
            Contact Us for Tickets
          </Link>
      </Container>
    </section>
  );
};

export default AakdEvent;
